<script
    lang="ts"
    setup
>
    import type { TariffsToggle } from '~/ts/types/tariff'

    type Props = {
        modelValue: TariffsToggle
    }

    type Emit = {
        (event: 'update:modelValue', value: TariffsToggle): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const discountPercentage = 40

    const inputModel = useSyncProp<string>(props, 'modelValue', emit)
</script>

<template>
    <div class="flex items-center justify-center mt-4 tablet:mt-10 max-tablet:w-full">
        <div class="flex gap-2 p-2 border-[1px] border-secondary-active rounded-[100px]">
            <button
                :class="[ 'px-4', $style['toggle-item'], { [ $style['active'] ]: inputModel === 'month' } ]"
                @click="inputModel = 'month'"
            >
                <span>
                    {{ $t('index-tariffs-3') }}
                </span>
            </button>

            <button
                :class="[ 'pl-4 pr-2', $style['toggle-item'], { [ $style['active'] ]: inputModel === 'year' } ]"
                @click="inputModel = 'year'"
            >
                <span key="text">
                    {{ $t('index-tariffs-4') }}
                </span>

                <span class="px-3 py-2 rounded-[100px] bg-[#DFF1D9] text-[#00A807]">
                    -{{ discountPercentage }}%
                </span>
            </button>
        </div>
    </div>
</template>

<style
    lang="sass"
    scoped
    module
>
    .toggle-item
        @apply flex items-center gap-2 h-[50px] font-medium uppercase cursor-pointer rounded-[100px] transition-colors hover:bg-action-hover active:bg-action-active

        &.active
            @apply bg-black text-white
</style>

import type { WritableComputedRef } from 'vue'

export const useSyncProp = <T>(
    props: any,
    key: string,
    emit: any,
): WritableComputedRef<T> => {
    return computed<T>({
        get() {
            return props[key]
        },
        set(value) {
            emit(`update:${ key }`, value)
        },
    })
}

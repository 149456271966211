<script
    lang="ts"
    setup
>
    type Props = {
        price: number
        primary?: boolean
        onlyHeader?: boolean
        oldPrice?: number
    }

    const props = withDefaults(defineProps<Props>(), {
        primary: false,
        onlyHeader: false,
        oldPrice: undefined,
    })
</script>

<template>
    <div class="flex">
        <div class="flex flex-col max-tablet:flex-1 gap-6 border-[1px] border-secondary-active rounded-[20px]">
            <IndexSectionTariffsPlansPlanHeader
                :primary="props.primary"
                :price="props.price"
                :old-price="props.oldPrice"
            >
                <template #title>
                    <slot name="title" />
                </template>
            </IndexSectionTariffsPlansPlanHeader>

            <IndexSectionTariffsPlansPlanList
                v-if="!props.onlyHeader"
                key="plan-1"
                :primary="props.primary"
            />

            <IndexSectionTariffsPlansPlanFooter
                v-if="!props.onlyHeader"
                key="plan-2"
                :primary="props.primary"
            />
        </div>
    </div>
</template>

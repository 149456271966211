<script
    lang="ts"
    setup
>
    type Props = {
        price: number
        primary?: boolean
        oldPrice?: number
    }

    const props = withDefaults(defineProps<Props>(), {
        primary: false,
        oldPrice: undefined,
    })

    const secondaryTextColor = computed<string>(() => (props.primary ? 'text-[#7D6F2B]' : 'text-secondary-text'))

    const price = ref<Props['price']>(props.price)
    const oldPrice = ref<Props['oldPrice']>(props.oldPrice)

    const priceAnimation = {
        intervals: [],
        ms: 20,
        stop(): void {
            this.intervals.forEach(clearInterval)
        },
        animate(targetRef: Ref<number>, targetNumber: number): void {
            let currentValue = targetRef.value

            if (currentValue < targetNumber) {
                const interval = setInterval(() => {
                    currentValue += 1

                    if (currentValue >= targetNumber) {
                        clearInterval(interval)
                    }

                    targetRef.value = +currentValue.toFixed(2)
                }, this.ms)

                this.intervals.push(interval)
            } else {
                const interval = setInterval(() => {
                    currentValue -= 1

                    if (currentValue <= targetNumber) {
                        clearInterval(interval)
                    }

                    targetRef.value = +currentValue.toFixed(2)
                }, this.ms)

                this.intervals.push(interval)
            }
        },
    }

    onMounted(() => {
        watch(props, (value) => {
            priceAnimation.stop()

            if (price.value !== value.price) {
                priceAnimation.animate(price, value.price || 0)
            }

            if (oldPrice.value !== value.oldPrice) {
                oldPrice.value = value.oldPrice
            }
        })
    })
</script>

<template>
    <div
        :class="[
            'flex flex-col justify-between gap-1 m-2 tablet:w-[384px] min-h-[208px] py-5 px-6 rounded-[14px]',
            (props.primary ? 'bg-primary' : 'bg-secondary'),
        ]"
    >
        <div class="flex flex-col">
            <div class="font-medium text-[32px] leading-[130%] uppercase">
                <slot name="title" />
            </div>

            <div :class="[ 'font-medium leading-[130%] lowercase', secondaryTextColor ]">
                {{ $t('index-tariffs-7') }}
            </div>
        </div>

        <div class="flex flex-col">
            <div
                v-show="props.oldPrice"
                :class="[
                    'text-[32px] font-medium leading-[130%] tracking-tight line-through',
                    secondaryTextColor,
                ]"
            >
                ${{ oldPrice }}
            </div>

            <div
                key="price"
                class="flex items-end"
            >
                <div class="text-[60px] font-medium leading-[100%] tracking-tighter">
                    ${{ price }}
                </div>

                <div :class="[ 'ml-2 font-medium leading-[130%] lowercase', secondaryTextColor ]">
                    /{{ $t('index-tariffs-3') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script
    lang="ts"
    setup
>
    type Props = {
        primary?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        primary: false,
    })

    const linkToCabinet = useCabinetAuthUrl('login')
</script>

<template>
    <hr class="mt-auto border-secondary">

    <div class="flex flex-col gap-4 px-8 pb-6">
        <AppHtmlContent :content="$t(props.primary ? 'index-tariffs-15' : 'index-tariffs-14')" />

        <AppLink
            class="flex"
            external
            :to="linkToCabinet"
        >
            <AppButton
                large
                class="flex-1"
            >
                {{ $t('index-tariffs-16') }}
            </AppButton>
        </AppLink>
    </div>
</template>

<script
    lang="ts"
    setup
>
    import type { TariffsToggle } from '~/ts/types/tariff'

    type Props = {
        toggleValue: TariffsToggle
        onlyHeaders?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        onlyHeaders: false,
    })

    const pricePerMonth = 24.99
    const pricePerMonthWithDiscount = 14.99

    const primaryPrice = computed<number>(() => (props.toggleValue === 'year') ? pricePerMonthWithDiscount : pricePerMonth)
</script>

<template>
    <div class="flex max-tablet:flex-col-reverse justify-between max-tablet:gap-6">
        <IndexSectionTariffsPlansPlan
            :only-header="props.onlyHeaders"
            :price="0"
        >
            <template #title>
                {{ $t('index-tariffs-5') }}
            </template>
        </IndexSectionTariffsPlansPlan>

        <IndexSectionTariffsPlansPlan
            primary
            :only-header="props.onlyHeaders"
            :price="primaryPrice"
            :old-price="(toggleValue === 'month') ? undefined : pricePerMonth"
        >
            <template #title>
                {{ $t('index-tariffs-6') }}
            </template>
        </IndexSectionTariffsPlansPlan>
    </div>
</template>

<script
    lang="ts"
    setup
>
    type Props = {
        primary?: boolean
    }

    type Item = {
        file: string
        title: string
        text: string
    }

    const props = withDefaults(defineProps<Props>(), {
        primary: false,
    })

    const items = computed<Item[]>(() => {
        const items: Item[] = [
            {
                file: 'index-tariffs-1',
                title: 'index-tariffs-8',
                text: 'index-tariffs-19',
            },
            {
                file: 'index-tariffs-2',
                title: 'index-tariffs-9',
                text: 'index-tariffs-20',
            },
        ]

        if (props.primary) {
            items.push(...[
                {
                    file: 'index-tariffs-3',
                    title: 'index-tariffs-25',
                    text: 'index-tariffs-30',
                },
                {
                    file: 'index-tariffs-4',
                    title: 'index-tariffs-10',
                    text: 'index-tariffs-21',
                },
                {
                    file: 'index-tariffs-5',
                    title: 'index-tariffs-29',
                    text: 'index-tariffs-34',
                },
                {
                    file: 'index-tariffs-6',
                    title: 'index-tariffs-11',
                    text: 'index-tariffs-22',
                },
                {
                    file: 'index-tariffs-7',
                    title: 'index-tariffs-26',
                    text: 'index-tariffs-31',
                },
                {
                    file: 'index-tariffs-8',
                    title: 'index-tariffs-12',
                    text: 'index-tariffs-23',
                },
                {
                    file: 'index-tariffs-9',
                    title: 'index-tariffs-13',
                    text: 'index-tariffs-24',
                },
                {
                    file: 'index-tariffs-10',
                    title: 'index-tariffs-27',
                    text: 'index-tariffs-32',
                },
                {
                    file: 'index-tariffs-11',
                    title: 'index-tariffs-28',
                    text: 'index-tariffs-33',
                },
            ])
        }

        return items
    })
</script>

<template>
    <div class="flex flex-col gap-4 px-8">
        <div
            v-for="item in items"
            :key="item.title"
            class="flex items-center gap-4"
        >
            <AppIconCheckBold color="#00A807" />

            <div class="flex items-center gap-2">
                <div>{{ $t(item.title) }}</div>

                <AppHintTooltip v-bind="item" />
            </div>
        </div>
    </div>
</template>
